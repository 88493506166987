import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "communication",
      "style": {
        "position": "relative"
      }
    }}>{`Communication`}</h2>
    <p>{`Our development workflows are starting to become more and more agile. However,
there is still one link in the chain where this fast and flexible way can't be
fully employed. Mediahuis has a dedicated design team that focuses on delivering
compelling user-flows for our products. It also has a multi-skilled development
team that converts these designs to performant user-facing applications. Alas,
both these teams don't employ the same mental models.`}</p>
    <p>{`As a result, there is no common ground between these two worlds. Both parties'
workflows contain steps that they have to repeat every time a new product is to
be delivered. Since designers start every design from scratch, they have to
recreate all base elements (such as buttons and headings.). This inconsistency
causes developers having to create a new implementation of all base elements
since they are not exact copies from the previous.`}</p>
    <p>{`In this process, no one is to blame, because both parties never established a
universal language. With a designer's tools and a developer's frameworks
evolving over the years, we can use these technological advancements to our
advantage to support us in establishing a common playing field.`}</p>
    <p>{`With Chameleon, our goal is to enable streamlined communication. If developers
learn to think like designers, and designers learn to think like developers, the
process of delivering a new product can be agile from start to finish.`}</p>
    <p>{`As a bonus, when designers don't have to focus on the low-level components
anymore, this frees up time. They can use this time to craft carefully
thought-out user flows, prototype faster and iterate more quickly with priority
on the user experience. On the other hand, this gives developers extra time to
focus on accessibility and stability.`}</p>
    <h2 {...{
      "id": "multibranding",
      "style": {
        "position": "relative"
      }
    }}>{`Multibranding`}</h2>
    <p>{`Mediahuis, as the name suggests, is a collection of a multitude of (media)
brands. From a design system's perspective, this poses an exciting challenge. On
the one hand, brands need to have the ability to express themselves through
their dedicated branding. On the other hand, to be able to communicate
effectively, all brands need to have the same building blocks at their disposal.`}</p>
    <p>{`By having a collection of low-level building blocks that are branded by design,
we satisfy the first need to enable multi branding easily. These building blocks
allow developers to create products without having to reinvent the wheel.`}</p>
    <p>{`The low-level nature of these building blocks allows brands to differentiate on
the composition level. With not too much set in stone, it should be easy to move
around the blocks that are at the heart of the composition to support the
branding even further.`}</p>
    <p>{`Adding a brand should also be a straightforward experience. With sensible
initial defaults and a few branded values, it should give new brands a clear
indication of what they might expect. These initial defaults all stem from a
common theme that contains fundamental values, from which the brand that is
being onboarded can then extend. Over time, the brand's dedicated them can then
override the fundamental values one by one, to gradually increase its specific
branding.`}</p>
    <h2 {...{
      "id": "single-source-of-truth",
      "style": {
        "position": "relative"
      }
    }}>{`Single source of truth`}</h2>
    <p>{`In the long run, our goal is also to become a single source of truth when it
comes to all things branding. A one-stop-shop for logos, icons, fonts, colors,
and everything else relating to the branding. The customers of this
one-stop-shop are scattered all over Mediahuis' departments. They can range
anywhere from executives requiring the latest version of a brand logo for an
external presentation to a marketer needing the exact hex-value of the brand's
color for their next Instagram post.`}</p>
    <p>{`This collection can then serve as a living style guide that brands can use to
inform external partners of specific brand branding guidelines.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      